<template>
  <div class="menu px-6 pt-6">
    <div class="flex justify-between items-center">
      <router-link
        :to="{
          name: 'Dashboard',
        }"
      >
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
          alt="Playda Logo"
          width="100"
          height="43"
        />
      </router-link>
      <div>
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/diy/menu_icon.webp"
          alt="Menu Icon"
          width="24"
          height="16"
          @click.prevent="showMenu = true"
        />
      </div>
    </div>
    <div
      class="bg-nColorButtonText fixed top-0 left-0 w-full h-screen z-10 p-6"
      v-if="showMenu"
    >
      <div class="flex justify-between items-center">
        <router-link
          :to="{
            name: 'Dashboard',
          }"
        >
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
            alt="Playda Logo"
            width="100"
            height="43"
          />
        </router-link>
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/menu_close.webp"
            alt="Menu Icon"
            width="24"
            height="16"
            @click.prevent="showMenu = false"
          />
        </div>
      </div>
      <div class="grid gap-2 mt-2">
        <div class="grid gap-1 mt-2" v-if="data">
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-white rounded-full"></div>
              <p class="font-InterSemiBold text-white text-sm">Unused</p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.available) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorOrange rounded-full"></div>
              <p class="font-InterSemiBold text-nColorOrange text-sm">
                Incomplete
              </p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.incomplete) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorBlue rounded-full"></div>
              <p class="font-InterSemiBold text-nColorBlue text-sm">Ready</p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.ready) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorStatusGreen rounded-full"></div>
              <p class="font-InterSemiBold text-nColorStatusGreen text-sm">
                Playing
              </p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.playing) }}
              </p>
            </div>
          </div>
          <div class="flex gap-2 items-center justify-between">
            <div class="flex gap-2 items-center">
              <div class="w-3 h-3 bg-nColorLightGray rounded-full"></div>
              <p class="font-InterSemiBold text-nColorLightGray text-sm">
                Played
              </p>
            </div>
            <div
              class="text-nColorYellow bg-nColorButtonText rounded px-2 py-1"
            >
              <p class="font-RobotoBlack text-lg">
                {{ formatNumber(data.played) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed bottom-6 w-10/12">
        <div>
          <div class="grid gap-2 mt-4">
            <p class="font-InterMedium text-nColorLightGray text-sm">
              For Custom Game Jams:
            </p>
            <router-link
              :to="{
                name: 'ContactUs',
              }"
              class="text-center font-ZuumeMedium border border-nColorYellow text-nColorYellow px-6 py-1 rounded-3xl text-2xl"
            >
              Contact Us
            </router-link>
          </div>
          <div class="grid gap-1 mt-2">
            <p class="text-nColorLightGray text-sm">
              Buy a plan & Create more Game Jams!
            </p>
            <router-link
              class="font-ZuumeMedium text-nColorButtonText bg-nColorYellow px-6 py-1 rounded-3xl mt-2 text-2xl text-center"
              :to="{
                name: 'Pricing',
              }"
            >
              Buy
            </router-link>
          </div>
          <div class="flex justify-center gap-1 mt-4">
            <router-link
              :to="{
                name: 'PurchaseHistory',
              }"
            >
              <div class="text-nColorYellow underline font-InterSemiBold">
                View Purchase History
              </div>
            </router-link>
          </div>
        </div>
        <div class="flex justify-between items-center mt-10">
          <div>
            <p class="font-InterBold text-base text-nColorOrange">
              {{ username }}
            </p>
            <p
              class="font-InterMedium text-nColorDarkGray opacity-60 text-sm text-nColorLightGray"
            >
              {{ email }}
            </p>
          </div>
          <div>
            <button
              class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput mt-4"
              @click.prevent="signOut()"
            >
              Sign Out
            </button>
          </div>
        </div>
        <div class="flex justify- gap-3 items-center">
          <router-link
            :to="{
              name: 'PrivacyPolicy',
            }"
          >
            <div
              class="text-nColorLightGray underline font-InterSemiBold text-xs"
            >
              Privacy Policy
            </div>
          </router-link>
          <router-link
            :to="{
              name: 'TermsAndConditions',
            }"
          >
            <div
              class="text-nColorLightGray underline font-InterSemiBold text-xs"
            >
              Terms & Conditions
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "MobileMenu",
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
      showMenu: false,
      data: null,
    };
  },
  created() {
    ApiService.get(apiResource.getDashboardMenuNumbers, {}).then((data) => {
      this.data = data.data.data;
    });
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    formatNumber(value) {
      return String(value).padStart(2, "0");
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped></style>
